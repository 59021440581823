.tab-button {
    padding: 10px 10px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .tab-button.active {
    background-color: #3182ce;
    color: white;
  }
  
  .tab-button:hover {
    background-color: #63b3ed;
  }